import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/ai_prediction_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/ai_prediction_blog_header.png");
const section_1 = require("../../../assets/img/blogs/ai_prediction_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/ai_prediction_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/ai_prediction_blog_img_4.png");
const section_4 = require("../../../assets/img/blogs/ai_prediction_blog_img_3.png");
const section_5 = require("../../../assets/img/blogs/ai_prediction_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/ai_prediction_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/ai_prediction_blog_img_7.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="AI Predictions for 2024: Navigating the Future"
        description="Learn AI predictions for 2024. The top predictions are open-source models, AI regulations, and leaner AI alternatives. "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt ai_prediction_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-smb  color-black line-height-18">
                    LLM or AI predictions in 2024
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-smb  color-black">
                    LLM or AI predictions <br />
                    in 2024
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Top 10 AI Predictions for 2024
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Top 10 AI Predictions for 2024
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                It’s more than a year now. AI hovers on top of the discussion
                topic for leaders. Companies start believing in the tangible{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  benefits of Generative AI
                </a>
                . Despite glaring concerns, leaders aren’t afraid of
                experimenting with Generative AI possibilities for their
                business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given that Generative AI or LLMs will be integrated into every
                vendor’s product offering, Generative AI or Artificial
                Intelligence will drive people to speculate about their future
                possibilities, downsides, and opportunities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, a heavily{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  LLM-based chatbot development company
                </a>
                , shares its insights on 2024 AI predictions. Discover our
                Generative AI or AI predictions and learn how you can design
                your perspectives.
              </p>
              <h3 className="font-section-sub-header-small">
                Open Source LLM on Acceleration in 2024
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="open source LLM models pace up fast 
"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                2023 saw the proliferation of closed-source LLM models for
                outstanding performance with human-level proficiencies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These models are built on a centralized cloud infrastructure.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The downside is that{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  consumer-grade products such as ChatGPT
                </a>{" "}
                and Bard provide limited access to their proprietary data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They need to be more flexible for training and personal use.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                2024 is the year of decentralized AI or open-source models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is observed that open-source LLM competitors quickly catch up
                with closed-source models in overall performance benchmarks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you look at the graph above released by ArkInvest, it
                exhibits steady progress by open-source models. The dominance is
                nothing less than closed-source models, which are getting closer
                in 2023.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Open-source LLMs are new yet much predictable in 2024. There are
                enough trends to support open-source LLM growth.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-testimonials-medium">
                  Big techs and startups are major contributors
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Meta and small players like Mixtral, Yi 34B, and Falcon put
                effort into research and development for their existing models.
                These models show significant performance similarity with their
                contenders, GPT 4.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The proliferation of open-source models is scaling at an
                incredible pace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                As the objective is to democratize Generative AI in a way
                similar to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  ChatGPT
                </a>{" "}
                and others, Meta aims to give free access to its foundation
                model, Llama, and make it accessible across all its consumer
                products.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is also predicted that Meta’s increased investment in
                hardware and reference to ‘we’re training’ significantly raises
                the speculation about the Llama 3 release.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                In tandem with the previous cadences of six months to train
                Llama 1 & 2, Llama 3 is highly likely to be released in early
                March 2024.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-testimonials-medium">
                  Ease of access to open-source LLM models
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Currently, there are as many as 32500 open-source{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  LLM platforms
                </a>
                on Hugging Face.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It indicates that open-source models are preferable for users
                for being more customizable, secure, cost-effective, and easy to
                iterate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Also, quantization techniques will allow users to apply the
                Mixture of Experts model to work independently on a small subset
                of models rather than the whole models. So, with less compute
                power, open-source contenders can scale up fast. For example,
                Mistral largely uses this technique to build its open-source
                models.
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI Transparency and Regulations in 2024
              </h3>

              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="AI regulations 2024"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The pace of foundation model development will increase in 2024.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, more threats exist, such as{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  deep fakes, transparency, and platform accountability
                </span>{" "}
                nuances.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So far, we aren’t unaware of fiascos happening around{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  security mishaps with regular GPT models such as ChatGPT.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Google’s latest GPT-4 variant,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Gemini
                </span>
                , raises accountability questions on its truthfulness. A video
                made to display Gemini’s capabilities turned out to be heavily
                doctored.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In 2024, AI governance and regulation policy sets new standards
                for AI companies.
              </p>
              <ul className="float-left w-100 blogs_ul_upd">
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Friendly AI policy -{" "}
                  </span>
                  In the US, new AI policies will allow AI agencies to craft
                  their best practices to regulate how they want to apply and
                  implement AI technologies. This allows for a more tailored
                  approach for AI companies across different economic sectors.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI risk mapping -{" "}
                  </span>
                  The US National Institute of Standards and Technology will
                  likely roll out a framework that urges each agency to measure
                  the risk factors their AI applications may pose to users.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI transparency for foundation models -{" "}
                  </span>
                  The new EU AI Act will be stricter in 2024. Though it allows
                  some AI applications to work independently, agencies
                  developing foundation models need to comply with the strict
                  policy of the AI Act. These{" "}
                  <a href="https://workativ.com/">AI companies</a> must document
                  how they train their foundation models so they can later be
                  audited to ensure their systems are secure and minimize risks
                  of biases.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Content moderation on the Internet -{" "}
                  </span>
                  2024 is the year of elections worldwide. Deep fakes are an
                  escalating threat to people’s identity. When AI can be used to
                  fight fake content or deep fake videos, synthetic data is
                  spurring for building evil bots or more such deep fake videos
                  to bombard the internet with fake content. Meta, Discord, and
                  TikTok are creating new tools to minimize the deep fake risks
                  in the election atmosphere. Simultaneously, X proposes to
                  charge some fee for every new bot on its platform to curb
                  access to personal data.
                </li>
              </ul>

              <h3 className="color-black font-section-sub-header-small">
                AI Spending Soars
              </h3>

              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" AI predictions 2024 and AI spending"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The AI race has just begun.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-transformation-guide-gen-ai">
                  CFOs want to drive more value for their businesses using
                  Generative AI or its former version.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With varying use cases transforming various business functions
                across the enterprise and consumer sides, AI and cloud spending
                would increase by 20%-30% in 2024.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also on this growth radar sits Nvidia.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Stock analysts are eyeing the chip and hardware maker as it
                continues to build massive iterations in the GenAI ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Nvidia’s AI computing platforms are a go-to product for leading
                AI developer communities, including Hugging Face, Google, and
                Tesla.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Nvidia’s stocks hitting $1 trillion, it demonstrates the
                significance of chip and data center solutions to augment AI
                development and companies’ interest in driving growth using AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On a different note, according to IDC’s Worldwide Artificial
                Intelligence Systems Spending Guide (August 2023), businesses
                already invested $ 166 billion in AI development in 2023.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Surprisingly, a new investment trajectory will surpass the
                previous growth rate by 26.9% of the CAGR growth rate. With
                that, AI investment will grow to $423 billion by 2027.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                Leaner Alternatives to ChatGPT or Branded LLM Tools
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the growing interest in democratized AI tools, non-AI
                companies want to expand into LLM GPT development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                NVIDIA leads as the powerful GPU and CPU hardware maker.
                Spearheading innumerable LLM model developments for its
                customers, they want to expand into LLM development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Experts also predict that alternative models are possible with
                AI’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/leveraging-the-value-of-ai-self-service-workativ">
                  self-learning capabilities.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This could be a cost-effective way for users to work with
                slightly cheaper GenAI or LLM models than leading brands.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                AI Integration into Daily Applications
              </h3>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="AI predictions 2024"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a race to capture maximum value in the AI age.
                Product-based companies want to win the competition with a
                strong customer profile.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To give elevated experiences to app users, brands prefer using
                AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  Generative AI tools
                </a>{" "}
                inside apps to double down efficiency and experience for them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The core idea is to personalize offers and increase customer
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Apps to trade, play music, create images, or binge-watch will
                heavily infest AI properties without our knowledge.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As per Gartner, a{" "}
                <a href="https://www.gartner.com/en/articles/gartner-top-10-strategic-technology-trends-for-2024">
                  third of all new apps will integrate Generative AI-powered
                  user interfaces by 2026
                </a>
                . This will help elevate{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                  user experience
                </a>{" "}
                and drive revenues for businesses.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                Monetizing GPT Isn’t Easy on the ChatGPT Store
              </h3>

              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="AI predictions 2024 and chatgpt store "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI developers can have free access to developer tools to create
                new GPT applications for ChatGPT stores.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, experts believe the experience will not be as authentic
                as with the Apple store.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The security is the primary reason. On top of that, GPTs may
                look similar and offer similar kinds of experience or task
                accomplishments.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, what’s the need to pay for a similar experience if users can
                access ChatGPT without spending a single penny?
              </p>
              <h3 className="color-black font-section-sub-header-small">
                More AI-related Jobs Than Only ML Engineers
              </h3>

              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="AI predictions 2024 more AI jobs "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Until recently, we only knew a few AI jobs—data scientists, ML
                engineers, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Going forward, job seekers will have more options in the AI
                field to scale their careers.
              </p>
              <ul className="float-left w-100 blogs_ul_upd margin-ul-btm">
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Prompt engineers -&nbsp;
                  </span>
                  Generative AI training requires particular prompts to guide
                  GenAI solutions to learn and generate outputs as users desire.
                  Prompt engineers must carefully check training data before
                  ingesting it inside a model.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI product manager -&nbsp;
                  </span>
                  This role involves overseeing AI products from ideation to
                  execution. For AI product managers, ensuring the product works
                  well as desired is essential.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI ethicist -&nbsp;
                  </span>
                  We all know about hallucinations and misinformation LLM can
                  spread. AI ethicists closely oversee AI performance to avoid
                  such indifferent behavior.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    AI compliance specialists -&nbsp;
                  </span>
                  AI models or applications must work with the evolving trends.
                  AI regulatory executives ensure their models follow the
                  correct set of governance to stay competitive.
                </li>
              </ul>
              <h3 className="color-black font-section-sub-header-small">
                Database Stores Will Emerge as Vector Stores
              </h3>

              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="AI predictions 2024 vector databases                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Semantic search or vector search is synonymous with Generative
                AI search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI interfaces need vector databases to improve search
                performance, an integrated requirement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Databases significantly help with data storage and model
                training. However, the evolving needs demand data conversion
                into vector data or mathematical representation to improve
                search results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To solve the purpose of the business, database platforms are
                ready to transform into vector databases and increase the usage
                of their platforms for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  RAG development
                </a>{" "}
                or vector searches.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Databricks joined the bandwagon. However, we can expect some big
                players, such as Snowflake, to emerge as vector stores.
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI Agents Will Proliferate
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2024 will bring significant growth for AI agent development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI agents are no longer known for everyday NLP tasks
                such as writing emails or generating documents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We can see more specialized agents who can talk to each other
                and perform tasks by making decisions together.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Big companies tend to use their existing data to build
                personalized AI agents and monetize them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We already see Bloomberg has built its own LLM model for its
                clients using its massive data repository. Who can say that
                Bloomberg can launch more intelligent AI agents for global users
                to derive financial insights and improve investment decisions?
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI for Customer Support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We already see the best use cases of Generative AI in content
                creation, AI-backed chatbots, and the automation of human tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                According to Gartner, the wide use of Generative AI is better
                optimized by customer support. 38% of leaders surveyed confirmed
                using Generative AI to improve customer experience and
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Generative AI helping customer support to generate call
                scripts, enable self-service, and drive AI personalization, IBM
                indicates that Generative AI will be the brain of the contact
                center.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Similarly, Generative AI aims to transform employee support for
                IT incident management,{" "}
                <a href="https://workativ.com/features.html">
                  IT support, HR support,
                </a>{" "}
                and employee retention and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/">Workativ</a> makes it easy for
                business leaders to leverage Generative AI employee support and
                transform their workplaces. Contact us if you want to bring out
                your LLM-powered chatbot for employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI-powered solutions are a significant technology that can
                reshape our work processes and add value to employee experience
                that helps grow revenue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses are undeniably emphasizing adapting to the evolving
                changes on the technology side to remain competitive.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI and other AI developments aim to bring tangible
                value to businesses of any size. Staying abreast of changes is
                critical to surviving business challenges and improving
                sustainability.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to look at AI predictions and act appropriately
                to create an adaptive AI environment and leverage its benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you are looking for AI solutions for your employee support,
                Workativ can help. Let’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  connect
                </a>{" "}
                and discuss what suits your business use cases best.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Top 10 AI Predictions for 2024
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Top 10 AI Predictions for 2024
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                It’s more than a year now. AI hovers on top of the discussion
                topic for leaders. Companies start believing in the tangible{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  benefits of Generative AI
                </a>
                . Despite glaring concerns, leaders aren’t afraid of
                experimenting with Generative AI possibilities for their
                business processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given that Generative AI or LLMs will be integrated into every
                vendor’s product offering, Generative AI or Artificial
                Intelligence will drive people to speculate about their future
                possibilities, downsides, and opportunities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, a heavily{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  LLM-based chatbot development company
                </a>
                , shares its insights on 2024 AI predictions. Discover our
                Generative AI or AI predictions and learn how you can design
                your perspectives.
              </p>
              <h3 className="font-section-sub-header-small">
                Open Source LLM on Acceleration in 2024
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="open source LLM models pace up fast 
"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                2023 saw the proliferation of closed-source LLM models for
                outstanding performance with human-level proficiencies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These models are built on a centralized cloud infrastructure.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The downside is that{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  consumer-grade products such as ChatGPT
                </a>{" "}
                and Bard provide limited access to their proprietary data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They need to be more flexible for training and personal use.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                2024 is the year of decentralized AI or open-source models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is observed that open-source LLM competitors quickly catch up
                with closed-source models in overall performance benchmarks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you look at the graph above released by ArkInvest, it
                exhibits steady progress by open-source models. The dominance is
                nothing less than closed-source models, which are getting closer
                in 2023.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Open-source LLMs are new yet much predictable in 2024. There are
                enough trends to support open-source LLM growth.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-testimonials-medium">
                  Big techs and startups are major contributors
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Meta and small players like Mixtral, Yi 34B, and Falcon put
                effort into research and development for their existing models.
                These models show significant performance similarity with their
                contenders, GPT 4.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The proliferation of open-source models is scaling at an
                incredible pace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                As the objective is to democratize Generative AI in a way
                similar to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                  ChatGPT
                </a>{" "}
                and others, Meta aims to give free access to its foundation
                model, Llama, and make it accessible across all its consumer
                products.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It is also predicted that Meta’s increased investment in
                hardware and reference to ‘we’re training’ significantly raises
                the speculation about the Llama 3 release.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                In tandem with the previous cadences of six months to train
                Llama 1 & 2, Llama 3 is highly likely to be released in early
                March 2024.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-testimonials-medium">
                  Ease of access to open-source LLM models
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Currently, there are as many as 32500 open-source{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  LLM platforms
                </a>
                on Hugging Face.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                It indicates that open-source models are preferable for users
                for being more customizable, secure, cost-effective, and easy to
                iterate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Also, quantization techniques will allow users to apply the
                Mixture of Experts model to work independently on a small subset
                of models rather than the whole models. So, with less compute
                power, open-source contenders can scale up fast. For example,
                Mistral largely uses this technique to build its open-source
                models.
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI Transparency and Regulations in 2024
              </h3>

              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="AI regulations 2024"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The pace of foundation model development will increase in 2024.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, more threats exist, such as{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  deep fakes, transparency, and platform accountability
                </span>{" "}
                nuances.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So far, we aren’t unaware of fiascos happening around{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  security mishaps with regular GPT models such as ChatGPT.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Google’s latest GPT-4 variant,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Gemini
                </span>
                , raises accountability questions on its truthfulness. A video
                made to display Gemini’s capabilities turned out to be heavily
                doctored.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In 2024, AI governance and regulation policy sets new standards
                for AI companies.
              </p>
              <ul className="float-left w-100 blogs_ul_upd">
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Friendly AI policy -{" "}
                  </span>
                  In the US, new AI policies will allow AI agencies to craft
                  their best practices to regulate how they want to apply and
                  implement AI technologies. This allows for a more tailored
                  approach for AI companies across different economic sectors.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI risk mapping -{" "}
                  </span>
                  The US National Institute of Standards and Technology will
                  likely roll out a framework that urges each agency to measure
                  the risk factors their AI applications may pose to users.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI transparency for foundation models -{" "}
                  </span>
                  The new EU AI Act will be stricter in 2024. Though it allows
                  some AI applications to work independently, agencies
                  developing foundation models need to comply with the strict
                  policy of the AI Act. These{" "}
                  <a href="https://workativ.com/">AI companies</a> must document
                  how they train their foundation models so they can later be
                  audited to ensure their systems are secure and minimize risks
                  of biases.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Content moderation on the Internet -{" "}
                  </span>
                  2024 is the year of elections worldwide. Deep fakes are an
                  escalating threat to people’s identity. When AI can be used to
                  fight fake content or deep fake videos, synthetic data is
                  spurring for building evil bots or more such deep fake videos
                  to bombard the internet with fake content. Meta, Discord, and
                  TikTok are creating new tools to minimize the deep fake risks
                  in the election atmosphere. Simultaneously, X proposes to
                  charge some fee for every new bot on its platform to curb
                  access to personal data.
                </li>
              </ul>

              <h3 className="color-black font-section-sub-header-small">
                AI Spending Soars
              </h3>

              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" AI predictions 2024 and AI spending"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The AI race has just begun.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-transformation-guide-gen-ai">
                  CFOs want to drive more value for their businesses using
                  Generative AI or its former version.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With varying use cases transforming various business functions
                across the enterprise and consumer sides, AI and cloud spending
                would increase by 20%-30% in 2024.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also on this growth radar sits Nvidia.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Stock analysts are eyeing the chip and hardware maker as it
                continues to build massive iterations in the GenAI ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Nvidia’s AI computing platforms are a go-to product for leading
                AI developer communities, including Hugging Face, Google, and
                Tesla.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Nvidia’s stocks hitting $1 trillion, it demonstrates the
                significance of chip and data center solutions to augment AI
                development and companies’ interest in driving growth using AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On a different note, according to IDC’s Worldwide Artificial
                Intelligence Systems Spending Guide (August 2023), businesses
                already invested $ 166 billion in AI development in 2023.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Surprisingly, a new investment trajectory will surpass the
                previous growth rate by 26.9% of the CAGR growth rate. With
                that, AI investment will grow to $423 billion by 2027.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                Leaner Alternatives to ChatGPT or Branded LLM Tools
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the growing interest in democratized AI tools, non-AI
                companies want to expand into LLM GPT development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                NVIDIA leads as the powerful GPU and CPU hardware maker.
                Spearheading innumerable LLM model developments for its
                customers, they want to expand into LLM development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Experts also predict that alternative models are possible with
                AI’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/leveraging-the-value-of-ai-self-service-workativ">
                  self-learning capabilities.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This could be a cost-effective way for users to work with
                slightly cheaper GenAI or LLM models than leading brands.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                AI Integration into Daily Applications
              </h3>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="AI predictions 2024"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a race to capture maximum value in the AI age.
                Product-based companies want to win the competition with a
                strong customer profile.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To give elevated experiences to app users, brands prefer using
                AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  Generative AI tools
                </a>{" "}
                inside apps to double down efficiency and experience for them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The core idea is to personalize offers and increase customer
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Apps to trade, play music, create images, or binge-watch will
                heavily infest AI properties without our knowledge.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As per Gartner, a{" "}
                <a href="https://www.gartner.com/en/articles/gartner-top-10-strategic-technology-trends-for-2024">
                  third of all new apps will integrate Generative AI-powered
                  user interfaces by 2026
                </a>
                . This will help elevate{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                  user experience
                </a>{" "}
                and drive revenues for businesses.
              </p>

              <h3 className="color-black font-section-sub-header-small">
                Monetizing GPT Isn’t Easy on the ChatGPT Store
              </h3>

              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="AI predictions 2024 and chatgpt store "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI developers can have free access to developer tools to create
                new GPT applications for ChatGPT stores.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, experts believe the experience will not be as authentic
                as with the Apple store.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The security is the primary reason. On top of that, GPTs may
                look similar and offer similar kinds of experience or task
                accomplishments.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, what’s the need to pay for a similar experience if users can
                access ChatGPT without spending a single penny?
              </p>
              <h3 className="color-black font-section-sub-header-small">
                More AI-related Jobs Than Only ML Engineers
              </h3>

              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="AI predictions 2024 more AI jobs "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Until recently, we only knew a few AI jobs—data scientists, ML
                engineers, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Going forward, job seekers will have more options in the AI
                field to scale their careers.
              </p>
              <ul className="float-left w-100 blogs_ul_upd margin-ul-btm">
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Prompt engineers-
                  </span>
                  Generative AI training requires particular prompts to guide
                  GenAI solutions to learn and generate outputs as users desire.
                  Prompt engineers must carefully check training data before
                  ingesting it inside a model.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI product manager -
                  </span>
                  This role involves overseeing AI products from ideation to
                  execution. For AI product managers, ensuring the product works
                  well as desired is essential.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    AI ethicist -
                  </span>
                  We all know about hallucinations and misinformation LLM can
                  spread. AI ethicists closely oversee AI performance to avoid
                  such indifferent behavior.
                </li>
                <li className="color-black font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    AI compliance specialists-
                  </span>
                  AI models or applications must work with the evolving trends.
                  AI regulatory executives ensure their models follow the
                  correct set of governance to stay competitive.
                </li>
              </ul>
              <h3 className="color-black font-section-sub-header-small">
                Database Stores Will Emerge as Vector Stores
              </h3>

              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="AI predictions 2024 vector databases                 "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Semantic search or vector search is synonymous with Generative
                AI search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                GenAI interfaces need vector databases to improve search
                performance, an integrated requirement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Databases significantly help with data storage and model
                training. However, the evolving needs demand data conversion
                into vector data or mathematical representation to improve
                search results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To solve the purpose of the business, database platforms are
                ready to transform into vector databases and increase the usage
                of their platforms for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  RAG development
                </a>{" "}
                or vector searches.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Databricks joined the bandwagon. However, we can expect some big
                players, such as Snowflake, to emerge as vector stores.
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI Agents Will Proliferate
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2024 will bring significant growth for AI agent development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI agents are no longer known for everyday NLP tasks
                such as writing emails or generating documents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We can see more specialized agents who can talk to each other
                and perform tasks by making decisions together.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Big companies tend to use their existing data to build
                personalized AI agents and monetize them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We already see Bloomberg has built its own LLM model for its
                clients using its massive data repository. Who can say that
                Bloomberg can launch more intelligent AI agents for global users
                to derive financial insights and improve investment decisions?
              </p>
              <h3 className="color-black font-section-sub-header-small">
                AI for Customer Support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                We already see the best use cases of Generative AI in content
                creation, AI-backed chatbots, and the automation of human tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                According to Gartner, the wide use of Generative AI is better
                optimized by customer support. 38% of leaders surveyed confirmed
                using Generative AI to improve customer experience and
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Generative AI helping customer support to generate call
                scripts, enable self-service, and drive AI personalization, IBM
                indicates that Generative AI will be the brain of the contact
                center.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Similarly, Generative AI aims to transform employee support for
                IT incident management,{" "}
                <a href="https://workativ.com/features.html">
                  IT support, HR support,
                </a>{" "}
                and employee retention and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/">Workativ</a> makes it easy for
                business leaders to leverage Generative AI employee support and
                transform their workplaces. Contact us if you want to bring out
                your LLM-powered chatbot for employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                AI-powered solutions are a significant technology that can
                reshape our work processes and add value to employee experience
                that helps grow revenue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses are undeniably emphasizing adapting to the evolving
                changes on the technology side to remain competitive.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI and other AI developments aim to bring tangible
                value to businesses of any size. Staying abreast of changes is
                critical to surviving business challenges and improving
                sustainability.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to look at AI predictions and act appropriately
                to create an adaptive AI environment and leverage its benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you are looking for AI solutions for your employee support,
                Workativ can help. Let’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  connect
                </a>{" "}
                and discuss what suits your business use cases best.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk"
              className="font-section-normal-text-testimonials"
            >
              The ROI of Using Chatbot and Automation with Your IT Service Desk​
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management"
              className="font-section-normal-text-testimonials"
            >
              AI in ITSM: Unlocking the Value of AI for IT Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
